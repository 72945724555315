import {
  contactNewsletterModalSuccess,
  contactNewsletterModalSuccessIncentiveVariant,
  footerContactNewsletter,
  footerContactNewsletterCommon,
  footerContactNewsletterIncentiveVariant,
  footerContactSubtext,
  footerContactSubtextIncentiveVariant,
} from '../translations';
import { useIncentiveVariantEnabled } from './useIncentiveVariantEnabled';

// Contact Newsletter Footer/Modal
export const useTranslationsFooterContactNewsletter = () => {
  const isIncentiveVariantEnabled = useIncentiveVariantEnabled();
  return {
    ...footerContactNewsletterCommon,
    ...(isIncentiveVariantEnabled
      ? {
          ...footerContactNewsletterIncentiveVariant,
          subtext: footerContactSubtextIncentiveVariant,
        }
      : { ...footerContactNewsletter, subtext: footerContactSubtext }),
  };
};

// Contact Newsletter Success Modal
export const useTranslationsContactNewsletterModalSuccess = () => {
  const isIncentiveVariantEnabled = useIncentiveVariantEnabled();

  return isIncentiveVariantEnabled
    ? contactNewsletterModalSuccessIncentiveVariant
    : contactNewsletterModalSuccess;
};
