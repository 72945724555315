import { RichTextFormatter } from '@/core/localization/translate';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { footerLegalConsumptionInformationHrefB2B as realFooterLegalConsumptionInformationHrefB2B, footerLegalMentionsHrefB2B as realFooterLegalMentionsHrefB2B, footerLegalProtectionHref as realFooterLegalProtectionHref, footerLegalTermsHrefB2B as realFooterLegalTermsHrefB2B } from './modules/LegalData/translations';
export const footerLegalConsumptionInformationHrefB2B = realFooterLegalConsumptionInformationHrefB2B;
export const footerLegalMentionsHrefB2B = realFooterLegalMentionsHrefB2B;
export const footerLegalProtectionHref = realFooterLegalProtectionHref;
export const footerLegalTermsHrefB2B = realFooterLegalTermsHrefB2B;

// Contact Newsletter Footer/Modal
export const footerContactNewsletterCommon = {
  ...translate({
    "inputLabel": "E-mail"
  }),
  legal: undefined
};
export const footerContactNewsletter = translate({
  "title": "Volete ricevere offerte imperdibili e consigli (davvero) utili?",
  "subtitle": "Iscriviti alla newsletter di ManoMano.",
  "subtextLink": "Per saperne di pi\xF9"
});
export const footerContactNewsletterIncentiveVariant = translate({
  "title": "Iscriviti alla nostra newsletter e ricevi un buono d'acquisto* di 5\u20AC, oltre a consigli e promozioni.",
  "subtitle": "*Codice promozionale inviato via mail in seguito all'iscrizione, valido per un ordine di minimo 150\u20AC di acquisti e per un mese, a decorrere dal giorno della ricezione.",
  "subtextLink": "Per saperne di pi\xF9"
});
export const footerContactSubtextIncentiveVariant = translateFormatter<{
  br: RichTextFormatter;
}>("La tua mail verr\xE0 utilizzata esclusivamente per inviarti le nostre newsletter. <br></br>Potrai disdire l'iscrizione seguendo il link integrato nella newsletter.", undefined, undefined, "it");
export const footerContactSubtext = translateFormatter<{
  br: RichTextFormatter;
}>("La tua mail verr\xE0 utilizzata esclusivamente per inviarti le nostre newsletter. <br></br>Potrai disdire l'iscrizione seguendo il link integrato nella newsletter.", undefined, undefined, "it");
// Contact Newsletter Success Modal
export const contactNewsletterModalSuccess = translate({
  "title": "Congratulazioni!",
  "subtitle": "Approfitta delle nostre promozioni esclusive per realizzare i tuoi progetti fai da te senza spendere una fortuna"
});
export const contactNewsletterModalSuccessIncentiveVariant = translate({
  "title": "Congratulazioni!",
  "subtitle": "\xC8 il vostro turno per ottenere le migliori offerte e le offerte speciali! Potrete armeggiare con il vostro portafogli in tutta tranquillit\xE0"
});
export const {
  availablePlayStore,
  detailsApp,
  detailsProApp,
  downloadAppStore,
  footerAboutSubTitle,
  footerAboutTitle,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerB2cConnectProLink,
  footerBestSellers,
  footerBestSellersHref,
  footerContactCountrySubTitle,
  footerContactCountryTitle,
  footerContactNewsletterInvalidError,
  footerContactNewsletterSubmitText,
  footerContactServiceCallPrice,
  footerContactServiceSubTitle,
  footerContactServiceTitle,
  footerContactSocialNetworkSubTitle,
  footerContactSocialNetworkTitle,
  footerContactSubTitle,
  footerContactTitle,
  footerManoExpress,
  footerManoExpressHref,
  footerManoExpressHrefB2B,
  footerNavigationAbout,
  footerNavigationAboutHref,
  footerNavigationAffiliation,
  footerNavigationAffiliationHref,
  footerNavigationBrands,
  footerNavigationBrandsHref,
  footerNavigationContactUs,
  footerNavigationContactUsHref,
  footerNavigationCouponLegalMention,
  footerNavigationCouponLegalMentionHref,
  footerNavigationHelp,
  footerNavigationHelpHref,
  footerNavigationOrders,
  footerNavigationOrdersHref,
  footerNavigationPressContact,
  footerNavigationPressContactHref,
  footerNavigationRecruitingSellers,
  footerNavigationRecruitingSellersHref,
  footerNavigationRecruitment,
  footerNavigationRecruitmentHref,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  footerProButtonTitle,
  footerQualityPaymentTitle,
  footerReportContentDSALabel,
  footerReportContentDSALink,
  footerSitemapAdvice,
  footerSitemapAdviceHref,
  footerSitemapCurrentOffers,
  footerSitemapCurrentOffersHref,
  footerSitemapProductCategories,
  footerSitemapProductCategoriesHref,
  footerSitemapInspirations,
  footerSitemapInspirationsHref,
  footerSitemapTopProducts,
  footerSitemapTopProductsHref,
  footerToTopButtonAriaLabel,
  footerTrustProSubtitle,
  footerTrustProTitle,
  titleApp,
  titleProApp
} = translate({
  "availablePlayStore": "Disponible su Google Play",
  "detailsApp": "pi\xF9 di 6 milioni di prodotti a portata di tasca",
  "detailsProApp": "*La nostra applicazione per i professionisti",
  "downloadAppStore": "Scarica su App Store",
  "footerAboutSubTitle": "Documenti importanti",
  "footerAboutTitle": "Approfondimenti",
  "footerAppStoreMamomanoUrl": "https://apps.apple.com/it/app/manomano/id1503142603",
  "footerAppStoreMamomanoProUrl": "https://apps.apple.com/it/app/manomano-pro/id1498180724",
  "footerB2cConnectProLink": "/connettersi?b2b=true",
  "footerBestSellers": "Migliori vendite",
  "footerBestSellersHref": "/bs",
  "footerContactCountrySubTitle": "A breve anche su Marte!",
  "footerContactCountryTitle": "Internazionali",
  "footerContactNewsletterInvalidError": "L'email inserita non \xE8 valida.",
  "footerContactNewsletterSubmitText": "Registrati",
  "footerContactServiceCallPrice": "Prezzo di una chiamata locale.",
  "footerContactServiceSubTitle": "Pronto? Risponde ManoMano!",
  "footerContactServiceTitle": "Servizio clienti",
  "footerContactSocialNetworkSubTitle": "Il magico mondo del web, in tutto il mondo!",
  "footerContactSocialNetworkTitle": "Seguici su",
  "footerContactSubTitle": "Manca solo il fax",
  "footerContactTitle": "Informazioni e contatti",
  "footerNavigationAbout": "Chi siamo?",
  "footerNavigationAboutHref": "https://about.manomano.com/it",
  "footerNavigationAffiliation": "Programma di Affiliazione",
  "footerNavigationAffiliationHref": "https://info.manomano.com/integra-il-nostro-programma/",
  "footerNavigationBrands": "I nostri marchi",
  "footerNavigationBrandsHref": "/marcas",
  "footerNavigationContactUs": "Contattaci",
  "footerNavigationContactUsHref": "/contatti",
  "footerNavigationCouponLegalMention": "Condizioni generali per l'utilizzo dei coupon",
  "footerNavigationCouponLegalMentionHref": "/lp/codici-promozionali-manomano-risparmia-sui-tuoi-acquisti-grazie-alle-nostre-offerte-esclusive-733",
  "footerNavigationHelp": "Hai bisogno di una mano?",
  "footerNavigationHelpHref": "/aiuto",
  "footerNavigationOrders": "Tracciabilit\xE0 dell'ordine",
  "footerNavigationOrdersHref": "/miei-ordini",
  "footerNavigationPressContact": "Contatti stampa",
  "footerNavigationPressContactHref": "https://about.manomano.com/it/notizie/",
  "footerNavigationRecruitingSellers": "Diventa commerciante",
  "footerNavigationRecruitingSellersHref": "/lp/vendi-su-manomano-2619",
  "footerNavigationRecruitment": "Reclutiamo",
  "footerNavigationRecruitmentHref": "https://careers.manomano.jobs/en",
  "footerPlaymarketMamomanoProUrl": "https://play.google.com/store/apps/details?id=com.manomano.pro&hl=it",
  "footerPlaymarketMamomanoUrl": "https://play.google.com/store/apps/details?id=com.manomano&hl=it",
  "footerProButtonTitle": "Accedi al tuo account Pro",
  "footerQualityPaymentTitle": "Pagamento sicuro",
  "footerReportContentDSALabel": "Segnala un contenuto illecito",
  "footerReportContentDSALink": "https://survey.zohopublic.eu/zs/Y2DHs5?lang=it",
  "footerSitemapAdvice": "Il nostro consiglio",
  "footerSitemapAdviceHref": "/il-nostro-consiglio",
  "footerSitemapCurrentOffers": "Le nostre offerte del momento",
  "footerSitemapInspirations": "Nostre ispirazioni",
  "footerSitemapInspirationsHref": "/consigli/le-nostre-ispirazioni-14915",
  "footerSitemapCurrentOffersHref": "/le-nostre-offerte-del-momento",
  "footerSitemapProductCategories": " Le categorie di prodotti pi\xF9 popolari",
  "footerSitemapProductCategoriesHref": "/le-categorie-di-prodotti-piu-popolari",
  "footerSitemapTopProducts": "I nostri confronti",
  "footerSitemapTopProductsHref": "/i-nostri-confronti",
  "footerManoExpress": "Scopri ManoExpress",
  "footerManoExpressHref": "/lp/manoexpress-3124",
  "footerManoExpressHrefB2B": "/lp/manoexpress-3125",
  "footerToTopButtonAriaLabel": "Torna su",
  "footerTrustProSubtitle": "Le tue marche preferite a prezzi imbattibili!",
  "footerTrustProTitle": "Sei un professionista della casa?",
  "titleApp": "L'app ManoMano",
  "titleProApp": "Scopri la app ManoManoPro*"
});